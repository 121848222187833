import React, { useState } from 'react'
import { Col, Row, Button } from 'react-bootstrap'
import { IoIosArrowRoundForward, IoIosArrowRoundBack } from "react-icons/io";
import Pagination from '../../components/pagination';
import GallaryImage from '../../components/gallaryimage';



const Gallary = () => {
    const data = ["16.jpg", "17.jpg", "18.jpg", "19.jpg", "20.jpg", "22.jpg", "10.jpg", "1.jpg", "11.jpg", "12.jpg", "13.jpg", "14.jpg", "21.jpg", "15.jpg", "16.jpg", "17.jpg", "18.jpg", "19.jpg", "20.jpg", "21.jpg", "22.jpg", "23.png", "26.jpg", "30.jpg", "31.jpg", "32.jpg", "33.jpg", "35.jpg", "37.jpg", "38.jpg", "39.jpg", "41.jpg", "43.jpg", "44.jpg", "45.jpg", "46.jpg", "47.jpg", "49.jpg"];
    const [perPage] = useState(8);
    const [current, setCurrent] = useState(1);

    // get Current Images
    const indexOfLastImage = current * perPage;
    const indexOfFirstImage = indexOfLastImage - perPage;
    const currentImage = data.slice(indexOfFirstImage, indexOfLastImage);

    const paginate = (pageNumber) => setCurrent(pageNumber)
    const handleNext = () => setCurrent(current + 1)
    const handlePrevious = () => setCurrent(current - 1)
    return (
        <div className='gallary'>
            <Row>
                <Col md={12}>
                    <h2>Original Arts</h2>
                </Col>
            </Row>
            <Row >
                <GallaryImage images={currentImage} />
            </Row>
            <div className='pagination'>
                <Button size="md" onClick={handlePrevious}><IoIosArrowRoundBack /> Previous</Button>
                <Pagination imgsPerPage={perPage} totalImgs={data?.length} active={current} paginate={paginate} />
                <Button onClick={handleNext}> Next <IoIosArrowRoundForward /></Button>
            </div>
        </div>
    )
}

export default Gallary