import React from 'react'

const Pagination = ({ imgsPerPage, totalImgs, active, paginate }) => {
    const pegeNumber = [];
    for (let i = 1; i < Math.ceil(totalImgs / imgsPerPage); i++) {
        pegeNumber.push(i)
    }
    return (
        <ul>
            {pegeNumber.map((number, k) => (
                <li key={k} className={active == k + 1 ? 'active' : ''} onClick={() => paginate(number)}>{number}</li>
            ))}
        </ul>
    )
}

export default Pagination