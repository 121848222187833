import React from 'react'

const GetQuot = () => {
    return (
        <div className='getquot'>
            <h2>Get a Quote</h2>
            <p>"I paint Thought, Feelings, a Sentiment, Spirituality, Reflection and Recreate the world around me on a canvas via Mixed Media.I want you to see what I see..." <strong>-Sanie Kaziu</strong> </p>
        </div>
    )
}

export default GetQuot