import React from 'react'
import { Row, Col, Image } from 'react-bootstrap'

const AboutMe = () => {
    return (
        <div className='aboutMe'>
            <Row>
                <Col md={12} className="p-0">
                    <Image src={require('./../../assets/images/uploads/12.jpg')} data-aos="slide-right" data-aos-duration="1000" alt='about-me' className="img-fluid" />
                </Col>

                <Col md={12} className="p-0">
                    <p data-aos="slide-left" data-aos-duration="1000">Sanie Kaziu is an emerging Artist in the Brooklyn, NY area whose artistic expression captures breathtaking realism through a medley of effortless, cool toned portraits. Inspired by the world around her, Sanie found her creative voice by way of the paintbrush in middle school. It was from there that she honed her skill into the development of a unique style that evokes the urban lure of the streets of Brooklyn, whilst also paying homage to her Islamic/Albanian heritage.</p>
                </Col>
            </Row>
        </div>
    )
}

export default AboutMe