import React from 'react'
import { Image } from 'react-bootstrap'
import LogoW from '../../assets/images/logo-w.svg'
const Footer = () => {
    return (
        <div className="footer">
            <div className='subFooter'>
                <Image src={LogoW} alt='footer-logo' />
                <span>Insomnia Painting LLC
                    Interior & Exterior  <br />Painting | Murals | Custom Artwork</span>
                <span>© 2017 Sanie Kaziu All Rights Reserved I New York</span>
            </div>
        </div>
    )
}

export default Footer