import React from 'react'
import Bedcrum from '../components/bedcrum'
import CardSlider from '../section/cardSlider'
import CustomOrder from '../section/customorder'
import GetQuot from '../section/getquote'
import Gallary from '../section/gallary'
import Contact from '../section/contact'

const Home = () => {
    document.title = "INSOMNIA | SALE";
    return (
        <>
            <Bedcrum active="Orignal Art" title="For Sale" />
            <CardSlider />
            <CustomOrder />
            <GetQuot />
            <Gallary />
            <Contact />
        </>
    )
}

export default Home