import React from 'react'
import CardItem from '../../components/card'
import Slider from "react-slick";
import { Col, Row } from 'react-bootstrap';


const CardSlider = () => {

    var settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <div className='itemslider'>
            <Row>
                <Slider {...settings}>
                    <Col md={3}>
                        <CardItem img="3.png" />
                    </Col>
                    <Col md={3}>
                        <CardItem img="30.jpg" />
                    </Col>
                    <Col md={3}>
                        <CardItem img="23.png" />
                    </Col>
                    <Col md={3}>
                        <CardItem img="22.jpg" />
                    </Col>

                </Slider>
            </Row>
        </div>
    )
}

export default CardSlider