import React from 'react'
import { Image } from 'react-bootstrap'

const Vedio = () => {
    return (
        <div className='vedio_section'>
            <div className='vedio-overlay'></div>
        </div>
    )
}

export default Vedio