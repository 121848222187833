import React from 'react'
import { Card, Row, Col, Button } from 'react-bootstrap';
import { IoIosStar } from "react-icons/io";

const CardItem = ({ img }) => {
    console.log("img", img)
    return (
        <Card className='cardItem m-2'>
            <Card.Img variant="top" src={require(`../../assets/images/uploads/${img}`)} />
            <Card.Body>
                <Card.Title><span>Deep In Thought</span> <span>  <IoIosStar /> 5.0</span></Card.Title>
                <Card.Text>
                    <Row>
                        <Col md={7}>
                            <span>Excluding Sales Tax Free Shipping</span>
                        </Col>
                        {/* <Col md={5}>
                            <h5>$1,000.00</h5>
                        </Col> */}
                    </Row>
                    {/* <Row>
                        <Col md={12}>
                            <div className="d-grid gap-2 mt-2">
                                <Button variant="dark" size="sm">
                                    Add To Cart
                                </Button>
                            </div>
                        </Col>
                    </Row> */}
                </Card.Text>
            </Card.Body>
        </Card>
    )
}

export default CardItem