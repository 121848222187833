import React from 'react'
import { Routes, Route } from "react-router-dom";
import Layout from "./../components/layout";


//  Pages
import Home from '../pages/Home';
import AboutUs from '../pages/AboutUs';


const index = () => {
    return (
        <Layout>
            <Routes>
                <Route path="/" element={<AboutUs />} />
                <Route path="/sale" element={<Home />} />
            </Routes>
        </Layout>
    )
}

export default index