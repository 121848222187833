import React from 'react'
import { Image } from 'react-bootstrap'
import LogoB from '../../assets/images/logo-b.svg'
import { BiMenu, BiUser, BiCart, BiSearch } from "react-icons/bi";
import { Link } from 'react-router-dom';

const Header = () => {
    return (
        <div className="navbarTop">
            <div className='logo'>
                <Link to="/">
                    <Image src={LogoB} alt='header-logo' />
                </Link>

            </div>
            <div className='burger'>
                <Link to="/sale">
                    <div className='burger_icon'>
                        <BiMenu />
                    </div>
                </Link>
            </div>
            <div className='icons'>
                <BiSearch />
                <BiCart />
                <BiUser />
            </div>
        </div>
    )
}

export default Header