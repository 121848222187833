import React from 'react'
import { Col, Image } from 'react-bootstrap'
const GallaryImage = ({ images }) => {
    return (
        <>
            {images.map((i, k) => (
                <Col xs={6} sm={6} md={3} key={k} data-aos="zoom-in" data-aos-duration="1000" data-aos-delay={k * 500}>

                    <div className='imgDiv'>
                        <Image src={require(`./../../assets/images/uploads/${i}`)} alt="gallary" />
                    </div>
                </Col>
            ))}
        </>

    )
}

export default GallaryImage