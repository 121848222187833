import React from 'react'
import Header from '../header'
import Footer from '../footer'
import { Container } from 'react-bootstrap'



const Layout = ({ children }) => {
    return (
        <div className="wrap">
            <Container>
                <Header />
                {children}
            </Container>
            <Footer />
        </div>
    )
}

export default Layout