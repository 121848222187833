import React from 'react'
import Bedcrum from '../components/bedcrum'
import AboutMe from '../section/aboutme'
import Gallary from '../section/gallary'
import CustomOrder from '../section/customorder'
import Contact from '../section/contact'
import Vedio from '../section/vedio'


const AboutUs = () => {
    document.title = "INSOMNIA | ABOUT ME";
    return (
        <>
            <Bedcrum active="" title="About Me" />
            <AboutMe />
            <Vedio />
            <Gallary />
            <CustomOrder />
            <Contact />

        </>
    )
}

export default AboutUs