import React from 'react'
import { Col, Row, Form, Button } from 'react-bootstrap'

const Contact = () => {
    return (
        <div className='contactUs'>
            <Row>
                <Col md={6} className='last'>
                    <div className="contactForm">
                        <h2>Get in touch</h2>
                        <span>Our friendly team would love to hear from you.</span>

                        <Row>
                            <Col md={12} className="mt-4">
                                <Form.Group className="mb-1" controlId="firstname">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control type="email" placeholder="Example. Adnan Sami" />
                                </Form.Group>

                                <Form.Group className="mb-1" controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control type="email" placeholder="you@company.com" />
                                </Form.Group>

                                <Form.Group className="mb-1" controlId="exampleForm.ControlTextarea1">
                                    <Form.Label>Message</Form.Label>
                                    <Form.Control as="textarea" rows={3} />
                                </Form.Group>

                                <div className="d-grid gap-2 mt-5">
                                    <Button variant="dark" size="sm">
                                        Send message
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>
                <Col md={6} className="p-0 first">
                    <div className="imgDivContact">
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Contact